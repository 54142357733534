import { Formik } from 'formik';
import { Link } from 'gatsby';
import { auth, googleProvider, useAuth } from 'gatsby-theme-firebase';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import * as Yup from 'yup';

import Loadable from '@loadable/component';
import { Input } from '@rebass/forms/styled-components';

import Chat from '../components/Chat';
import Layout from '../components/layout';
import Logo from '../components/Logo';
import SEO from '../components/seo';

const LoadablePlayer = Loadable(() => import('../components/Player'));

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  background: #111;
  z-index: 3;
`;

const CODE = 'sample';

const STORAGE_KEY = 'screening_authorized';

const SamplePage = () => {
  const [authorized, setAuthorized] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!localStorage) {
      return;
    }

    setAuthorized(Boolean(localStorage.getItem(STORAGE_KEY)));
    setReady(true);
  }, []);

  const schema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .lowercase()
          .required('Access code is required')
          .test('valid-code', 'Invalid access code', value => value === CODE),
      }),
    []
  );

  const handleSubmit = (values: any) => {
    if (values.code.toLowerCase() === CODE) {
      setAuthorized(true);
      localStorage.setItem(STORAGE_KEY, 'valid');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const user = await auth.signInWithPopup(googleProvider());
    } catch (err) {
      console.error('Authentication Error: ', err);
    }
  };

  return (
    <Layout>
      <SEO title="Sample Screening" />
      <Flex
        flexDirection="column"
        flex={1}
        bg="#000"
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          paddingRight: authorized ? 300 : 0,
        }}
      >
        {!authorized && ready && (
          <Flex
            flex={1}
            justifyContent="center"
            alignItems="center"
            p={3}
            sx={{ height: '100%' }}
          >
            <Flex flexDirection="column" flex={1} sx={{ maxWidth: 450 }}>
              <Box mb={3}>
                <Link to="/">
                  <Logo dark />
                </Link>
              </Box>
              <Flex
                flex={1}
                bg="#f6b91a"
                flexDirection="column"
                p={3}
                color="#000"
                sx={{ borderRadius: 5 }}
              >
                <Formik
                  initialValues={{ code: '' }}
                  validationSchema={schema}
                  onSubmit={handleSubmit}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Text mb={3} fontWeight={500} color="#000">
                        Access code is required to view this screening
                      </Text>
                      <Flex>
                        <Flex flex={1} pr={3}>
                          <Input
                            name="code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.code}
                            bg="transparent"
                            color="#000"
                          />
                        </Flex>
                        <Button
                          type="submit"
                          variant="outline"
                          color="#000"
                          sx={{ cursor: 'pointer', borderColor: '#000' }}
                        >
                          GO
                        </Button>
                      </Flex>
                      {errors.code && touched.code && (
                        <Text mt={3} color="red">
                          {errors.code}
                        </Text>
                      )}
                    </form>
                  )}
                </Formik>
              </Flex>
            </Flex>
          </Flex>
        )}
        {authorized && (
          <>
            <Flex px={3} py={3}>
              <Link to="/">
                <Logo dark />
              </Link>
            </Flex>
            <Flex width={1} flexDirection="column" bg="#222" px={3} py={3}>
              <Text
                fontSize={5}
                sx={{ textTransform: 'uppercase' }}
                color="#555"
              >
                Blood Sugar Rising
              </Text>
              {/* <Text color="#444" fontWeight={300} fontSize={3}>
                Screening Available{' '}
                {moment()
                  .subtract(1, 'hour')
                  .format('dddd, MMMM, Do h a')}{' '}
                -{' '}
                {moment()
                  .add(2, 'hour')
                  .format('h a')}
              </Text> */}
            </Flex>
            <Flex
              bg="#000"
              flex={1}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Flex flex={1} flexDirection="column" sx={{ minHeight: 436 }}>
                <LoadablePlayer src="https://cf98fa7b2ee4450e.mediapackage.us-east-1.amazonaws.com/out/v1/fe778940af1e4d27b0f9d688607bcd5d/index.mpd" />
              </Flex>
            </Flex>
            <Flex
              bg="#222"
              flex={1}
              py={3}
              px={3}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box>
                <Text color="#aaa" mb={2} fontSize={3}>
                  Welcome to the screening of Blood Sugar Rising, Hosted by SDU
                  School of Nursing
                </Text>
                <Text color="#aaa" mb={3} fontSize={2} fontWeight={300}>
                  7:00 pm film starts, 8:00pm Live Q&A Begins, Moderator: NAME,
                  Speakers: NAMES
                </Text>
                <Button variant="outline" color="yellow">
                  Join Discussion
                </Button>
              </Box>
            </Flex>
          </>
        )}
      </Flex>
      {authorized && (
        <Wrapper>
          <Chat channel="sample-live" />
        </Wrapper>
      )}
    </Layout>
  );
};

export default SamplePage;
